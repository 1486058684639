import game from './slices/game.slices';
import raffle from './slices/raffle.slices';
import popup from './slices/popup.slices';
import configs from './slices/configs.slices';
import time from './slices/time.slices';
import balance from './slices/balance.slices';
import drawHistory from './slices/drawHistory.slices';
import ticket from './slices/ticket.slices';
import scene from './slices/scene.slices';
import token from './slices/token.slices';
import sentTicket from './slices/sentTicket.slices';
import userCurrentHistory from './slices/userCurrentHistory.slices';
import userHistory from './slices/userHistory.slices';
import version from './slices/gameVersion.slices';

export const reducers = {
  game,
  raffle,
  popup,
  configs,
  time,
  balance,
  drawHistory,
  ticket,
  scene,
  token,
  sentTicket,
  userCurrentHistory,
  userHistory,
  version,
};

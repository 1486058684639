import React from 'react';
import s from './Leaderboard.module.scss';
import goldenCup from '../../../../assets/images/Leaderboard/1.png';
import silverCup from '../../../../assets/images/Leaderboard/2.png';
import bronzeCup from '../../../../assets/images/Leaderboard/3.png';

function Leaderboard() {
  return (
    <div className={s.leaderboard}>
      <div className={s.leaderboard__header}>
        <div className={s.leaderboard__top}>
          <div className={s.leaderboard__cup}>
            <img src={goldenCup} alt="Golden Cup" className={s.leaderboard__img} />
          </div>
          {/* <div className={s.leaderboard__prize}>
            <div className={s.leaderboard__id}>ID: 6****1</div>
          </div> */}
        </div>
        <div className={s.leaderboard__top}>
          <div className={s.leaderboard__cup}>
            <img src={silverCup} alt="Golden Cup" className={s.leaderboard__img} />
          </div>
          {/* <div className={s.leaderboard__prize}>
            <div className={s.leaderboard__id}>ID: 6****1</div>
          </div> */}
        </div>
        <div className={s.leaderboard__top}>
          <div className={s.leaderboard__cup}>
            <img src={bronzeCup} alt="Golden Cup" className={s.leaderboard__img} />
          </div>
          {/* <div className={s.leaderboard__prize}>
            <div className={s.leaderboard__id}>ID: 6****1</div>
          </div> */}
        </div>
        {/* <div className={s.leaderboard__top}>
          <div className={s.leaderboard__cup}>
            <img src={bronzeCup} alt="Golden Cup" className={s.leaderboard__img} />
          </div>
          <div className={s.leaderboard__prize}>
            <div className={s.leaderboard__id}>ID: 6****1</div>
            <div className={s.leaderboard__amount}></div>
          </div>
        </div> */}
      </div>
      <div className={s.comingSoon}>{'Coming Soon'}</div>
      {/* <div className={s.leaderboard__body}>
        <div className={s.leaderboard__info}>
          <div className={s.leaderboard__winner}>Player ID</div>
          <div className={s.leaderboard__won}>Won</div>
        </div>
        <div className={s.leaderboard__board}>
          <ul className={s.leaderboard__list}>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
            <li className={s.leaderboard__item}>
              <div className={s.leaderboard__playerInfo}>
                <div className={s.leaderboard__place}>1</div>
                <div className={s.leaderboard__player}>ID:6547894</div>
              </div>
              <div className={s.leaderboard__playerWon}>10 000 000</div>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default Leaderboard;

import React, { memo, useEffect, useState } from 'react';
import s from './Digit.module.scss';
import classNames from 'classnames';
import digits from '../../../../constants/digits.constants';

const Digit = ({ digit, className, empty, animate }) => {
  const [isAnimationStart, setIsAnimationStart] = useState(false);
  const color = digits[digit] ? digits[digit] : [];

  useEffect(() => {
    if (digit && animate) {
      setIsAnimationStart(true);
    }
    setTimeout(() => setIsAnimationStart(false), 500);
  }, [digit, animate]);

  return (
    <div
      className={classNames(s.digit, digit && s[color.toLowerCase()], {
        [className]: className,
        [empty]: empty,
        [s.show]: isAnimationStart,
      })}>
      {digit && <div className={s.digitText}>{digit}</div>}
    </div>
  );
};

export default memo(Digit);

import React from 'react';
import s from './Tab.module.scss';
import classNames from 'classnames';
import { useTranslation } from '../../../../context/LanguageContext';

function Tab({ icon, title, active, onClick }) {
  const t = useTranslation();
  return (
    <div
      className={classNames(s.tab, { [s.active]: active === title })}
      onClick={onClick}>
      <div className={s.tab__icon}>
        <svg className={s.tab__svg}>
          <use xlinkHref={`#${icon}`} />
        </svg>
      </div>
      {active === title && <div className={s.tab__title}>{t(title)}</div>}
    </div>
  );
}

export default Tab;

import click from './click.wav';
import win from './win.wav';
import bet from './bet.wav';
import start from './gameStart.wav';
import ballDrop from './ballDrop.wav';

const sounds = {
  click,
  win,
  bet,
  start,
  ballDrop,
};

export default sounds;

import { createSlice } from '@reduxjs/toolkit';
import { sentTicketExtraReducer } from '../thunks/sentTicket.thunk';

const initialState = { addedTickets: [], isLoading: false };

export const sendTicketSlices = createSlice({
  name: 'sentTicket',
  initialState,
  extraReducers: sentTicketExtraReducer,
});

// SELECTORS
export const selectAddedTickets = (state) => state.sentTicket.addedTickets;
export const selectNewBalance = (state) => state.sentTicket.balance;
export const selectBetLoading = (state) => state.sentTicket.isLoading;

export default sendTicketSlices.reducer;

import React, { useEffect, useState } from 'react';
import { useSoundConstext } from '../../../../../context/SoundContext';
import s from './TicketBodyBalls.module.scss';
import { useSelector } from 'react-redux';
import { selectActiveDigits, selectActiveSound } from '../../../../../redux/slices/game.slices';
import { selectBigDigit, selectRaffleActive } from '../../../../../redux/slices/raffle.slices';
import Digit from '../../../DigitsBoard/Digit/Digit';
import classNames from 'classnames';

function TicketBodyBalls({ index, addedTicket, ticket, nextAddedTicket, ticketArray }) {
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectActiveSound);
  const [winDigits, setWinDigits] = useState([]);
  const isRaffleActive = useSelector(selectRaffleActive);
  const activeDigits = useSelector(selectActiveDigits);
  const bigDigit = useSelector(selectBigDigit);

  const winComb = ticket?.r;
  const balls = ticket?.bet[index];
  const winIndex = winComb?.indexOf(balls) + 1;

  useEffect(() => {
    if (isRaffleActive && bigDigit) {
      setWinDigits((state) => [...state, bigDigit]);
    } else {
      setWinDigits([]);
    }
  }, [bigDigit, isRaffleActive]);

  useEffect(() => {
    if (!nextAddedTicket && bigDigit == Number(balls)) {
      isSoundActive && useSoundData.win();
    }
  }, [bigDigit]);

  return (
    <div
      className={classNames(s.ticket__num, {
        [s.won]: !nextAddedTicket && addedTicket && winDigits.includes(Number(balls)),
      })}>
      <div className={classNames(s.ticket__coef, { [s.won]: true })} data-text={`${winIndex}`}>
        <Digit
          digit={
            (ticketArray[index] = addedTicket ? Number(ticket.bet[index]) : activeDigits[index])
          }
          className={s.digit}
        />
      </div>
    </div>
  );
}

export default TicketBodyBalls;

import { MOBILE_FOOTER_TABS } from '../constants/tabs.constants';
import History from '../components/Common/Tabs/History/History.jsx';
import Jackpot from '../components/Common/Tabs/Jackpot/Jackpot.jsx';
import Leaderboard from '../components/Common/Tabs/Leaderboard/Leaderboard.jsx';
import GameboardMobile from '../components/Mobile/GameboardMobile/GameboardMobile';
import { mobileTabsImgs } from '../assets/images/MobileTabs/mobileTabsImgs';

const { CURRENT, HISTORY, JACKPOT, LEADERBOARD } = MOBILE_FOOTER_TABS;

export const MOBILE_TABS_CONFIGS = {
  [CURRENT]: {
    icon: mobileTabsImgs.currentMobile,
    activeIcon: mobileTabsImgs.currentMobileActive,
    Component: GameboardMobile,
  },
  [HISTORY]: {
    icon: mobileTabsImgs.History,
    activeIcon: mobileTabsImgs.HistoryActive,
    Component: History,
  },
  [JACKPOT]: {
    icon: mobileTabsImgs.Jackpot,
    activeIcon: mobileTabsImgs.JackpotActive,
    Component: Jackpot,
  },
  [LEADERBOARD]: {
    icon: mobileTabsImgs.Leaderboard,
    activeIcon: mobileTabsImgs.LeaderboardActive,
    Component: Leaderboard,
  },
};

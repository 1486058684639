import { createSlice } from '@reduxjs/toolkit';
import { drawHistoryExtraReducer } from '../thunks/drawHistory.thunk';

const initialState = {};

export const drawHistorySlices = createSlice({
  name: 'drawHistory',
  initialState,
  extraReducers: drawHistoryExtraReducer,
});

// SELECTORS
export const selectDrawHistory = state => state.drawHistory.drawHistory;
export const selectWinCombination = state => state.drawHistory.winCombination;
export const selectDrawId = state => state.drawHistory.drawId;

export default drawHistorySlices.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDrawHistoryThunk = createAsyncThunk(
  'drawHistory/getDrawHistoryThunk',
  async function (duration) {
    const response = await axios.get(`get-draw-history`, {
      params: {
        duration: duration,
        limit: 10,
        page: 0,
      },
    });
    return response;
  },
);

export const drawHistoryExtraReducer = builder => {
  builder
    .addCase(getDrawHistoryThunk.pending, state => {
      state.isLoading = true;
    })
    .addCase(getDrawHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.drawHistory = payload.drawHistory;
      state.drawId = payload.drawHistory[0].id;
      state.winCombination = payload.drawHistory[0].r;
    })
    .addCase(getDrawHistoryThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};

import React, { memo } from 'react';
import s from './GamePlayMobile.module.scss';
import Coefficient from '../../Common/Tabs/Coefficient/Coefficient';
import DigitsBoard from '../../Common/DigitsBoard/DigitsBoard';
import Ticket from '../../Common/Ticket/Ticket';
import Submit from '../../Desktop/Submit/Submit';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectUserHistoryAddedTickets } from '../../../redux/slices/userHistory.slices';
import { selectDrawId } from '../../../redux/slices/drawHistory.slices';
import { useTranslation } from '../../../context/LanguageContext';
import { selectUserCurrentHistory } from '../../../redux/slices/userCurrentHistory.slices';

const GamePlayMobile = ({ isRaffleActive }) => {
  const t = useTranslation();
  const drawId = useSelector(selectDrawId);
  const userHistoryAddedTickets = useSelector(selectUserHistoryAddedTickets);
  const userCurrentHistory = useSelector(selectUserCurrentHistory);
  const countedTickets =
    userHistoryAddedTickets && userHistoryAddedTickets.filter((ticket) => ticket.draw == drawId);

  return (
    <div className={classNames(s.gamePlayMobile, { [s.active]: isRaffleActive })}>
      <div
        className={classNames(s.gamePlayMobile__inner, {
          [s.raffle]: isRaffleActive,
        })}>
        <Coefficient isRaffleActive={isRaffleActive} />
        {isRaffleActive && (
          <div className={s.ticketsContainer}>
            {countedTickets.length ? (
              <div className={s.gamePlayMobile__TicketList}>
                {countedTickets.map((ticket, index) => (
                  <Ticket
                    key={index}
                    index={index}
                    isRaffleActive={isRaffleActive}
                    ticket={ticket}
                    addedTicket
                  />
                ))}
              </div>
            ) : (
              <div className={s.empty__TicketList}>
                <div>{t('You have no tickets in current draw.')}</div>
              </div>
            )}
            {userCurrentHistory.length > 0 && (
              <div className={s.nextDrawTicket}>
                <div className={s.nextDrawLine} />
                <div className={s.nextDrawText}>{t(`Next draw's tickets`)}</div>
                <div className={s.gamePlayMobile__TicketList}>
                  {userCurrentHistory.map((ticket, index) => (
                    <Ticket
                      key={index}
                      index={index}
                      ticket={ticket}
                      isRaffleActive={isRaffleActive}
                      addedTicket
                      nextAddedTicket
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {!isRaffleActive && <DigitsBoard />}
      </div>
      {!isRaffleActive && <Submit />}
    </div>
  );
};

export default memo(GamePlayMobile);

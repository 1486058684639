import React from 'react';
import s from './Jackpot.module.scss';
import jackpot from '../../../../assets/images/Jackpot/jackpotBanner.png';
// import classNames from 'classnames';
// import { jackpotData } from '../../../../constants/fackData.constants';

function Jackpot() {
  return (
    <div className={s.jackpot}>
      <div className={s.jackpot__header}>
        <div className={s.jackpot__title}>
          <img src={jackpot} alt="Jackpot" className={s.jackpot__img} />
        </div>
        {/* <div data-currency="Tzs" className={s.jackpot__amount}>
          520.000.000
        </div> */}
      </div>
      <div className={s.comingSoon}>{'Coming Soon'}</div>
      {/* <div className={s.jackpot__body}>
        <ul className={s.jackpot__list}>
          {jackpotData.map(el => (
            <li key={el.id} className={s.jackpot__item}>
              <div className={s.jackpot__info}>
                <div className={s.jackpot__prop}>ID: ${el.id}</div>
                <div className={classNames(s.jackpot__prop, s.bet)}>
                  Bet: ${el.bet}
                </div>
              </div>
              <div className={s.jackpot__border} />
              <div className={s.jackpot__info}>
                <div className={s.jackpot__prop}>{el.date}</div>
                <div className={s.jackpot__prop}>Time: {el.time}</div>
              </div>
              <div className={s.jackpot__border} />
              <div className={s.jackpot__info}>
                <div className={classNames(s.jackpot__prop, s.bet)}>
                  Jackpot
                </div>
                <div className={classNames(s.jackpot__prop, s.amount)}>
                  {el.amount}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}

export default Jackpot;

import React from 'react';
import s from './AmountMobile.module.scss';
import Button from '../../Common/UI/Button/Button';
import classNames from 'classnames';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAmount,
  setMobileInput,
} from '../../../redux/slices/game.slices';
import AmountInputMobile from './AmountInputMobile/AmountInputMobile';
import AmountOptionMobile from './AmountOptionMobile/AmountOptionMobile';
import AmountNumbersMobile from './AmountNumbersMobile/AmountNumbersMobile';
import { useDispatch } from 'react-redux';

const AmountMobile = () => {
  const dispatch = useDispatch();
  const selectedAmount = useSelector(selectAmount);

  return (
    <div className={s.amountMobile__container}>
      <div className={s.amountMobile}>
        <div className={s.amountMobile__title}>Amount</div>
        <AmountInputMobile selectedAmount={selectedAmount} />
        <AmountOptionMobile selectedAmount={selectedAmount} />
        <AmountNumbersMobile selectedAmount={selectedAmount} />
        <div className={s.amountMobile__submit}>
          <Button
            className={classNames(s.amountMobile__submitButton)}
            onClick={() => dispatch(setMobileInput())}
            text={'BET'}
          />
        </div>
        <div className={s.amountMobile__close}>
          <Button
            className={s.amountMobile__closeButton}
            onClick={() => dispatch(setMobileInput())}
            icon={'close'}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(AmountMobile);

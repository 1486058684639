export const GAME_DURATIONS = [1, 3];

export const STATUS_NUMBERS = {
  0: '---',
  2: 'Win',
  3: 'Loss',
};

export const SCENE_NAMES = {
  MENU_SCENE: 'MENU_SCENE',
  LOADING_SCENE: 'LOADING_SCENE',
  GAME_SCENE: 'GAME_SCENE',
};

export const PROCESS_SCENE = {
  CONGRATE_SCENE: 'CONGRATE_SCENE',
  WAITING_SCENE: 'WAITING_SCENE',
  DRAW_SCENE: 'DRAW_SCENE',
};

export const HEADER_BUTTONS = {
  HOWTOPLAY_BUTTON: 'HOWTOPLAY_BUTTON',
  SOUND_BUTTON: 'SOUND_BUTTON',
  FULLSCREEN_BUTTON: 'FULLSCREEN_BUTTON',
};

export const POPUP_TYPES = {
  HOWTOPLAY: 'HOWTOPLAY',
  ERROR: 'ERROR',
  SESSION_ERROR: 'SESSION_ERROR',
};

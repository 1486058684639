import { createSlice } from '@reduxjs/toolkit';
import { configsExtraReducer } from '../thunks/consfigs.thunk';

const initialState = {};

export const consfigsSlices = createSlice({
  name: 'configs',
  initialState,
  extraReducers: configsExtraReducer,
});

// SELECTORS
export const selectConfigs = (state) => state.configs;
export const selectDenomination = (state) => state.configs.denomination;
export const selectMaxBet = (state) => state.configs.maxBet;
export const selectMinBet = (state) => state.configs.minBet;
export const selectMaxWin = (state) => state.configs.maxWin;
export const selectOddMatrix = (state) => state.configs.odd_matrix;
export const selectUserId = (state) => state.configs.userId;
export const selectAmountRanges = (state) => state.configs.amountRanges;

export default consfigsSlices.reducer;

import React from 'react';
import s from './BetAmountOptions.module.scss';
import Button from '../../../Common/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmount, setAmountOptiosn } from '../../../../redux/slices/game.slices';
import { selectAmountRanges, selectMaxBet } from '../../../../redux/slices/configs.slices';
import { setErrorActive } from '../../../../redux/slices/popup.slices';
import { selectCurrency } from '../../../../redux/slices/balance.slices';
import { useTranslation } from '../../../../context/LanguageContext';

function BetAmountOptions({ denomination }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const maxBet = useSelector(selectMaxBet);
  const currency = useSelector(selectCurrency);
  const selectedAmount = useSelector(selectAmount);
  const amountRanges = useSelector(selectAmountRanges);

  const clickHandler = (amount) => {
    const isLessMaxbet = amount + +selectedAmount <= +maxBet;

    isLessMaxbet
      ? dispatch(setAmountOptiosn(amount))
      : dispatch(setErrorActive(`${t('Max bet is')} ${maxBet} ${currency}`));
  };

  return (
    <div className={s.amount__fastBet}>
      {amountRanges.map((item) => {
        return (
          <div key={item} className={s.amount__bet}>
            <Button
              className={s.amount__button}
              amountText={`+${item}`}
              onClick={() => clickHandler(item)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default BetAmountOptions;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tickets: [],
  showTicketInfo: false,
  nextDrawTicket: false,
};
export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    addTicket: (state, { payload }) => {
      state.tickets = [...state.tickets, payload];
    },
    resetAddedTickets: state => {
      state.tickets = [];
    },
    setTicketInfo: (state, { payload }) => {
      state.showTicketInfo = payload;
    },
    setNextDrawTicket: (state, { payload }) => {
      state.nextDrawTicket = payload;
    },
  },
});

// ACTIONS
export const {
  addTicket,
  resetAddedTickets,
  setTicketInfo,
  setNextDrawTicket,
} = ticketSlice.actions;

// SELECTORS
export const selectTickets = state => state.ticket.tickets;
export const selectTicketInfo = state => state.ticket.showTicketInfo;
export const selectIsNextDrawTicket = state => state.ticket.nextDrawTicket;

export default ticketSlice.reducer;

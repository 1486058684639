import { createSlice } from '@reduxjs/toolkit';
import { UserCurrentHistoryExtraReducer } from '../thunks/userCurrentHistory.thunk';

const initialState = {
  userCurrentHistory: [],
};

export const userCurrentHistorySlices = createSlice({
  name: 'userCurrentHistory',
  initialState,
  extraReducers: UserCurrentHistoryExtraReducer,
});

// SELECTORS
export const selectUserCurrentHistory = state =>
  state.userCurrentHistory.userCurrentHistory;

export default userCurrentHistorySlices.reducer;

import classNames from 'classnames';
import React from 'react';
import StartPageImg from '../../StartPage/StartPageImg/StartPageImg';
import s from './LoadingScene.module.scss';

function LoadingScene() {
  return (
    <div className={s.loadingPage}>
      <div className={s.loadingPage__body}>
        <StartPageImg />
      </div>
      <div className={classNames(s.progress)}>
        <div className={classNames(s.progressBar)} />
      </div>
    </div>
  );
}

export default LoadingScene;

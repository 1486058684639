import React from 'react';
import { useSelector } from 'react-redux';
import { ALL_WINING_PLACES } from '../../../../configs/game.configs';
import { useTranslation } from '../../../../context/LanguageContext';
import { selectCurrency } from '../../../../redux/slices/balance.slices';
import { selectMaxBet, selectMaxWin, selectMinBet } from '../../../../redux/slices/configs.slices';
import CoefficientTable from './CoefficienTable/CoefficientTable';
import s from './HowToPlay.module.scss';

const firstTablePlaces = ALL_WINING_PLACES.slice(0, 15);
const secondTablePlaces = ALL_WINING_PLACES.slice(15);

function HowToPlay() {
  const t = useTranslation();
  const maxWin = useSelector(selectMaxWin);
  const minBet = useSelector(selectMinBet);
  const maxBet = useSelector(selectMaxBet);
  const currency = useSelector(selectCurrency);

  return (
    <div className={s.popup__info}>
      <div className={s.popup__name}>{t('How to Play')}</div>
      <div className={s.popup__text}>
        <div className={s.popup__title}>{t('GAME RULES')}</div>
        <p>
          {`${t(
            'Lucky 6 is one of the types of lottery games where you can win up to',
          )} ${maxWin} ${currency}.`}
        </p>
        <p>
          {t('The principle of the game is to guess all the six numbers before the draw starts.')}
        </p>
        <p>{t('The available numerical range is 1 to 48.')}</p>
        <p>
          {t(
            'A drawing takes place in each 2 minutes during which 35 numbers out of 48 come out in sequence.',
          )}
        </p>
        <div className={s.popup__title}>{t('Conditions to have a ticket')}</div>
        <p>{t('Out of 48 numbers the players must pick 6 numbers that will form their ticket.')}</p>
        <p>{t('After picking all the 6 numbers the “place bet” button will get activated.')}</p>
        <p>{`${t(
          'The minimum bet is',
        )} ${minBet} ${currency} ${'and the maximum is'} ${maxBet} ${currency}.`}</p>
        <p>
          {t(
            'After accepting the draw the player gets a ticket for participating in the upcoming draw.',
          )}
        </p>
        <div className={s.popup__title}>{t('The draw conditions')}</div>
        <p>
          {t(
            'Out of 48 numbers, 35 come out randomly, sorted in order of exit, occupying 1 to 35 places in the table, which have their own coefficient of winning.',
          )}
        </p>
        <p>{t('If all the 6 picked numbers come out during the draw the ticket wins.')}</p>
        <div className={s.popup__title}>{t('The win coefficient')}</div>
        <p>
          {t(
            'After 35 balls go out the win amount is being decided which is based on the last match coefficient.',
          )}
        </p>
        <div className={s.popup__title}>{t('The coefficient table')}</div>
        <p>
          {t(
            'The 1 to 5 range does not have any coefficients as the ticket wins only if it has 6 matches.',
          )}
        </p>
        <div className={s.popup__tables}>
          <CoefficientTable places={firstTablePlaces} />
          <CoefficientTable places={secondTablePlaces} />
        </div>
        <div style={{ display: 'flex' }}>
          <p>{t('Example')}:&nbsp;</p>
          <div>
            <p>{t('the ticket includes these numbers - 5 18 40 26 10 7')}</p>
            <p>{`${t('bet amount - 150')} ${currency}`}</p>
            <p>{t('exit order - 10 14 25 5 13 18 7 9 26 40․․․․․․')}</p>
          </div>
        </div>
        <p>
          {t(
            'the full match of the ticket happened on the 10th place: The coefficient for the 10th place is 1000:',
          )}
        </p>
        <p>{t('The total win amount for this ticket is:')}</p>
        <p>{`${t('150(bet) * 1000(coefficient for 10th number) = 150.000')} ${currency}`}</p>
      </div>
    </div>
  );
}

export default HowToPlay;

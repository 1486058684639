import { createSlice } from '@reduxjs/toolkit';
import { UserHistoryExtraReducer } from '../thunks/userHistory.thunk';

const initialState = {
  userHistory: [],
};

export const userHistorySlices = createSlice({
  name: 'userHistory',
  initialState,
  extraReducers: UserHistoryExtraReducer,
});

// SELECTORS
export const selectUserHistory = state => state.userHistory.userHistory;
export const selectUserHistoryAddedTickets = state =>
  state.userHistory.userHistoryAddedTickets;

export default userHistorySlices.reducer;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from './components/Common/Popup/Popup';
import Wrapper from './components/Common/Wrapper/Wrapper';
import { selectDuration, setGameDuration } from './redux/slices/game.slices';
import { selectActivePopup, setSessionErrorActive } from './redux/slices/popup.slices';
import { setRaffleActive } from './redux/slices/raffle.slices';
import { setNextDrawTicket } from './redux/slices/ticket.slices';
import { selectTime } from './redux/slices/time.slices';
import { getBalanceThunk } from './redux/thunks/balance.thunk';
import { getConfigsThunk } from './redux/thunks/consfigs.thunk';
import { getDrawHistoryThunk } from './redux/thunks/drawHistory.thunk';
import { getTimeThunk } from './redux/thunks/time.thunk';
import { getUserHistoryThunk } from './redux/thunks/userHistory.thunk';
import { getGameVersionThunk } from './redux/thunks/gameVersion.thunk';
import { selectGameVersion } from './redux/slices/gameVersion.slices';

function App() {
  const dispatch = useDispatch();
  const duration = useSelector(selectDuration);
  const isPopupActive = useSelector(selectActivePopup);
  const time = useSelector(selectTime);

  useEffect(() => {
    dispatch(getGameVersionThunk());
    dispatch(getBalanceThunk());
  }, []);

  const gameVersion = useSelector(selectGameVersion);
  const isSingleVersion = gameVersion.length > 1;

  useEffect(() => {
    if (!isSingleVersion && gameVersion.length !== 0) {
      const version = gameVersion[0];
      dispatch(setGameDuration(version));
    }
  }, [isSingleVersion]);

  useEffect(() => {
    if (!!duration) {
      dispatch(getConfigsThunk(duration));
      dispatch(getDrawHistoryThunk(duration));
      dispatch(getUserHistoryThunk({ duration }));
    }
  }, [duration]);

  useEffect(() => {
    const timerInterval = requestTimer(time);

    return () => clearInterval(timerInterval);
  }, [time]);

  function requestTimer(sec) {
    let timerInSeconds = sec;
    const _timerCountDown = () => {
      timerInSeconds--;
      if (timerInSeconds === 1) {
        dispatch(getDrawHistoryThunk(duration));
      }
      if (timerInSeconds === 0) {
        dispatch(setRaffleActive(true));
        dispatch(setNextDrawTicket(false));
      }
      if (timerInSeconds <= 5 && timerInSeconds > 0) {
        dispatch(setNextDrawTicket(true));
      }
      if (timerInSeconds < 0) {
        setSessionErrorActive('Low internet connection, please try again.');
        dispatch(getTimeThunk(duration));
      }
    };
    return setInterval(() => _timerCountDown(), 1000);
  }

  return (
    <div>
      <Wrapper />
      {isPopupActive && <Popup isPopupActive={isPopupActive} />}
    </div>
  );
}

export default App;

import moment from 'moment-timezone';

export const CheckValidity = value => {
  const regex = /^[0-9]{0,9}?\d*\.?\d*$/;
  const valid = regex.test(value);
  if (
    !valid ||
    value[0] === '0' ||
    value[0] === '.' ||
    (value.split('.').length > 1 && value.split('.')[1].length > 2)
  ) {
    return false;
  } else {
    return true;
  }
};

export const getHourseAndMinutes = date => {
  let dateHours = date.getHours();
  let dateMinutes = date.getMinutes();

  return `${dateHours < 10 ? `0${dateHours}` : dateHours}:${
    dateMinutes < 10 ? `0${dateMinutes}` : dateMinutes
  }`;
};

export function getTimeFromIso(iso) {
  const parsed = new Date(iso);
  const minutes = parsed.getMinutes();
  const hours = parsed.getHours();
  let time = '';

  time += (hours < 10 ? '0' : '') + hours + ':';
  time += (minutes < 10 ? '0' : '') + minutes;

  return time;
}

export function getDayAndMonth(date) {
  let a = moment(date).format('MM/DD');
  return a;
}

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export function getLanguageFromUrl() {
  const splitUrl = window.location.href.split('/');
  const tokenMatch = splitUrl.find(el => el.includes('lang='));
  return tokenMatch ? tokenMatch.split('lang=')[1] : 'en';
}

import { createSlice } from '@reduxjs/toolkit';
import { MOBILE_FOOTER_TABS } from '../../constants/tabs.constants';

const { CURRENT } = MOBILE_FOOTER_TABS;

const initialState = {
  duration: 1,
  activeDigits: [],
  sound: true,
  amount: '',
  mobileActiveTab: CURRENT,
  mobileInput: false,
  activeLanguage: '',
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameDuration: (state, { payload }) => {
      state.duration = payload;
    },
    setActiveDigit: (state, { payload }) => {
      state.activeDigits = [...state.activeDigits, payload];
    },
    delActiveDigit: (state, { payload }) => {
      state.activeDigits = state.activeDigits.filter((item) => item !== payload);
    },
    resetActiveDigits: (state) => {
      state.activeDigits = [];
    },
    setRandomDigits: (state, { payload }) => {
      state.activeDigits = payload;
    },
    setCheckBoxDigits: (state, { payload }) => {
      state.activeDigits = payload;
    },
    setActiveSound: (state) => {
      state.sound = !state.sound;
    },
    setAmount: (state, { payload }) => {
      state.amount = payload;
    },
    setAmountOptiosn: (state, { payload }) => {
      state.amount = +state.amount + payload;
    },
    setMobileActiveTab: (state, { payload }) => {
      state.mobileActiveTab = payload;
    },
    setMobileInput: (state) => {
      state.mobileInput = !state.mobileInput;
    },
    setActiveLanguage: (state, { payload }) => {
      state.activeLanguage = payload;
    },
  },
});

// ACTIONS
export const {
  setGameDuration,
  setActiveDigit,
  delActiveDigit,
  resetActiveDigits,
  setCheckBoxDigits,
  setActiveSound,
  setAmount,
  setAmountOptiosn,
  setRandomDigits,
  setMobileActiveTab,
  setMobileInput,
  setActiveLanguage,
} = gameSlice.actions;

// SELECTORS
export const selectDuration = (state) => state.game.duration;
export const selectActiveDigits = (state) => state.game.activeDigits;
export const selectActiveSound = (state) => state.game.sound;
export const selectAmount = (state) => state.game.amount;
export const selectMobileActiveTab = (state) => state.game.mobileActiveTab;
export const selectMobileInput = (state) => state.game.mobileInput;
export const selectActiveLanguage = (state) => state.game.activeLanguage;

export default gameSlice.reducer;

import { HISTORY_TABS } from '../constants/tabs.constants';
import DrawHistory from '../components/Common/Tabs/History/DrawHistory/DrawHistory.jsx';
import MyHistory from '../components/Common/Tabs/History/MyHistory/MyHistory.jsx';

const { DRAW_HISTORY, MY_HISTORY } = HISTORY_TABS;

export const HISTORY_TABS_CONFIGS = {
  [DRAW_HISTORY]: {
    Component: DrawHistory,
  },
  [MY_HISTORY]: {
    Component: MyHistory,
  },
};

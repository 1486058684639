import { createSlice } from '@reduxjs/toolkit';
import { timeExtraReducer } from '../thunks/time.thunk';

const initialState = {};

export const timeSlices = createSlice({
  name: 'time',
  initialState,
  extraReducers: timeExtraReducer,
});

// SELECTORS
export const selectTime = state => state.time.time;
export const selectRaceId = state => state.time.raceId;

export default timeSlices.reducer;

import React from 'react';
import classNames from 'classnames';
import s from './StartPageDurations.module.scss';
import { useDispatch } from 'react-redux';
import { selectDuration, setGameDuration } from '../../../../redux/slices/game.slices';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../context/LanguageContext';
import { selectGameVersion } from '../../../../redux/slices/gameVersion.slices';

function StartPageDurations() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const selectedDuration = useSelector(selectDuration);
  const gameVersion = useSelector(selectGameVersion);

  const onDurationButtonClick = (duration) => {
    dispatch(setGameDuration(duration));
  };

  return (
    <div className={s.startPage__time}>
      {gameVersion.map((item) => (
        <div
          key={item}
          onClick={() => onDurationButtonClick(item)}
          className={classNames(s.startPage__min, {
            [s.active]: selectedDuration == item,
          })}>
          <button className={s.startPage__minBtn}>{`${item} ${t('min')}.`}</button>
        </div>
      ))}
    </div>
  );
}

export default StartPageDurations;

import React from 'react';
import s from './DrawHistory.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectRaffleActive } from '../../../../../redux/slices/raffle.slices';
import { selectDrawHistory } from '../../../../../redux/slices/drawHistory.slices';
import DrawHistoryContainer from './DrawHistoryContainer/DrawHistoryContainer';
import { useState } from 'react';

function DrawHistory() {
  const isRaffleActive = useSelector(selectRaffleActive);
  const drawHistorySelector = useSelector(selectDrawHistory);
  const [newDrawHitroryArr, setNewDrawHitroryArr] = useState([]);

  useEffect(() => {
    const filteredDrawHistoryArr = isRaffleActive
      ? drawHistorySelector.filter((item, _, arr) => item !== arr[0])
      : drawHistorySelector;
    setNewDrawHitroryArr(filteredDrawHistoryArr);
  }, [isRaffleActive, drawHistorySelector]);

  return (
    <div className={s.drawHistory}>
      <ul className={s.drawHistory__list}>
        {newDrawHitroryArr.map(item => (
          <DrawHistoryContainer key={item.id} item={item} />
        ))}
      </ul>
    </div>
  );
}

export default DrawHistory;

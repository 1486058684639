import React from 'react';
import s from './Footer.module.scss';
import Button from '../../Common/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMobileActiveTab,
  setMobileActiveTab,
} from '../../../redux/slices/game.slices';
import { MOBILE_TABS_CONFIGS } from '../../../configs/mobileTabs.configs';

const gameTabs = Object.keys(MOBILE_TABS_CONFIGS);

const Footer = () => {
  const dispatche = useDispatch();
  const mobileActiveTab = useSelector(selectMobileActiveTab);

  return (
    <footer className={s.footer}>
      <ul className={s.footer__list}>
        {gameTabs.map(tab => {
          return (
            <li className={s.footer__item} key={tab}>
              <Button
                mobileTabs
                className={s.footer__button}
                icon={
                  mobileActiveTab == tab
                    ? MOBILE_TABS_CONFIGS[tab].activeIcon
                    : MOBILE_TABS_CONFIGS[tab].icon
                }
                activeTab={mobileActiveTab}
                onClick={() => dispatche(setMobileActiveTab(tab))}
              />
            </li>
          );
        })}
      </ul>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { COEFFICIENT_BY_PLACE } from '../../../../../configs/game.configs';
import { useTranslation } from '../../../../../context/LanguageContext';
import s from './CoefficienTable.module.scss';

const CoefficientTable = ({ places }) => {
  const t = useTranslation();

  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th>{t('Place')}</th>
          <th>{t('Coefficient')}</th>
        </tr>
      </thead>
      <tbody>
        {places.map((place) => (
          <tr key={place}>
            <td>{place}</td>
            <td>{COEFFICIENT_BY_PLACE[place]}</td>
          </tr>
        ))}
        <tr />
      </tbody>
    </table>
  );
};

export default CoefficientTable;

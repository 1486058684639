import React from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_TABS_CONFIGS } from '../../../../configs/headerButtons.configs';
import { useTranslation } from '../../../../context/LanguageContext';
import {
  selectActiveSound,
  setActiveSound,
} from '../../../../redux/slices/game.slices';
import Button from '../../UI/Button/Button';

function SoundButton({ className, button }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const isSoundActive = useSelector(selectActiveSound);
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  const icon = MOBILE_TABS_CONFIGS[button]?.icon;
  const activeIcon = MOBILE_TABS_CONFIGS[button]?.activeIcon;
  const text = MOBILE_TABS_CONFIGS[button]?.text;
  const activeText = MOBILE_TABS_CONFIGS[button]?.activeText;

  return (
    <Button
      className={className}
      icon={isSoundActive ? icon : activeIcon}
      text={
        !isMobile
          ? isSoundActive
            ? `${t('Sound')} ${t(activeText)}`
            : `${t('Sound')} ${t(text)}`
          : ''
      }
      onClick={() => dispatch(setActiveSound())}
    />
  );
}

export default memo(SoundButton);

import React, { useState, useEffect } from 'react';
import s from './MyHistory.module.scss';
import classNames from 'classnames';
import MyHistoryHeader from './MyHistoryHeader/MyHistoryHeader';
import MyHistoryBody from './MyHistoryBody/MyHistoryBody';
import MyHistoryFooter from './MyHistoryFooter/MyHistoryFooter';
import { useSelector } from 'react-redux';
import { selectDuration } from '../../../../../redux/slices/game.slices';
import { STATUS_NUMBERS } from '../../../../../constants/game.constatnt';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { selectRaffleActive } from '../../../../../redux/slices/raffle.slices';
import { selectDrawId } from '../../../../../redux/slices/drawHistory.slices';

function MyHistory({ scrollableTarget }) {
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [tickets, setTickets] = useState([]);
  const duration = useSelector(selectDuration);
  const isRaffleActive = useSelector(selectRaffleActive);
  const drawId = useSelector(selectDrawId);

  useEffect(() => {
    getTickets(0);
  }, []);

  return (
    <div className={s.myHistory}>
      <ul className={s.myHistory__list}>
        <InfiniteScroll
          dataLength={tickets.length}
          next={() => getTickets(page)}
          hasMore={hasMore}
          scrollableTarget={scrollableTarget}
          loader={Loader}>
          {tickets &&
            tickets.map((item) => {
              const canShowStatus = !(isRaffleActive && item.draw === drawId);
              return (
                <li key={item.id} className={classNames(s.myHistory__item)}>
                  <MyHistoryHeader
                    id={item.id}
                    draw={item.draw}
                    date={item.dt}
                    canShowStatus={canShowStatus}
                    status={STATUS_NUMBERS[item?.ststatus]}
                  />
                  <div className={s.myHistory__body}>
                    <MyHistoryBody
                      digits={item.bet}
                      winComb={item.r}
                      canShowStatus={canShowStatus}
                    />
                    <MyHistoryFooter
                      canShowStatus={canShowStatus}
                      status={STATUS_NUMBERS[item?.ststatus]}
                      betting={item.sum}
                      winAmount={item.wsum}
                    />
                  </div>
                </li>
              );
            })}
        </InfiniteScroll>
      </ul>
    </div>
  );

  function getTickets(page) {
    axios
      .get(`get-user-draw-history`, {
        params: {
          duration,
          limit: 10,
          page,
        },
      })
      .then((response) => {
        const nextTickets = response?.userFinishedStakes;
        setHasMore(!!nextTickets.length);
        setTickets((prevState) => [...prevState, ...nextTickets]);
        setPage((prev) => prev + 1);
      });
  }
}

const Loader = (
  <div
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
    <div className={s.customLoader} />
  </div>
);

export default MyHistory;

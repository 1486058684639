export const colors = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
  BLUEBERRY: 'BLUEBERRY',
  BLUE: 'BLUE',
  PURPLE: 'PURPLE',
  GREY: 'GREY',
};

const { RED, ORANGE, YELLOW, GREEN, BLUEBERRY, BLUE, PURPLE, GREY } = colors;

const digits = {
  1: RED,
  2: ORANGE,
  3: YELLOW,
  4: GREEN,
  5: BLUEBERRY,
  6: BLUE,
  7: PURPLE,
  8: GREY,
  9: RED,
  10: ORANGE,
  11: YELLOW,
  12: GREEN,
  13: BLUEBERRY,
  14: BLUE,
  15: PURPLE,
  16: GREY,
  17: RED,
  18: ORANGE,
  19: YELLOW,
  20: GREEN,
  21: BLUEBERRY,
  22: BLUE,
  23: PURPLE,
  24: GREY,
  25: RED,
  26: ORANGE,
  27: YELLOW,
  28: GREEN,
  29: BLUEBERRY,
  30: BLUE,
  31: PURPLE,
  32: GREY,
  33: RED,
  34: ORANGE,
  35: YELLOW,
  36: GREEN,
  37: BLUEBERRY,
  38: BLUE,
  39: PURPLE,
  40: GREY,
  41: RED,
  42: ORANGE,
  43: YELLOW,
  44: GREEN,
  45: BLUEBERRY,
  46: BLUE,
  47: PURPLE,
  48: GREY,
};

export default digits;

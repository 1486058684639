import React, { useState } from 'react';
import { GAME_TABS_CONFIGS } from '../../../configs/tabs.consfigs';
import Tab from './Tab/Tab';
import s from './GameTabs.module.scss';
import { GAME_TABS } from '../../../constants/tabs.constants';
import { memo } from 'react';

const { COEFFICIENT } = GAME_TABS;
const gameTabs = Object.keys(GAME_TABS_CONFIGS);

function GameTabs() {
  const [activeTab, setActiveTab] = useState(COEFFICIENT);
  const activeTabConmponent = GAME_TABS_CONFIGS[activeTab];
  return (
    <div className={s.gameTabs}>
      <div className={s.gameTabs__header}>
        {gameTabs.map(tab => {
          return (
            <Tab
              key={tab}
              title={tab}
              icon={GAME_TABS_CONFIGS[tab].icon}
              active={activeTab}
              onClick={() => setActiveTab(tab)}
            />
          );
        })}
      </div>
      <div className={s.gameTabs__body}>
        <activeTabConmponent.Component />
      </div>
    </div>
  );
}

export default memo(GameTabs);

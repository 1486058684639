import React from 'react';
import s from './GameScene.module.scss';
import { useSelector } from 'react-redux';
import { selectActivePopup } from '../../../../redux/slices/popup.slices';
import Gameboard from '../../Gameboard/Gameboard';
import Popup from '../../Popup/Popup';

function GameScene() {
  const isPopupActive = useSelector(selectActivePopup);

  return (
    <div className={s.container}>
      <Gameboard />
      {isPopupActive && <Popup isPopupActive={isPopupActive} />}
    </div>
  );
}

export default GameScene;

import React from 'react';
import s from './TicketBody.module.scss';
import TicketBodyBalls from './TicketBodyBalls/TicketBodyBalls';

const ticketArray = new Array(6).fill(null);

function TicketBody({ addedTicket, ticket, nextAddedTicket }) {
  return (
    <div className={s.ticket__numbers}>
      {ticketArray.map((digit, index) => (
        <TicketBodyBalls
          key={index}
          ticketArray={ticketArray}
          digit={digit}
          index={index}
          addedTicket={addedTicket}
          ticket={ticket}
          nextAddedTicket={nextAddedTicket}
        />
      ))}
    </div>
  );
}

export default TicketBody;

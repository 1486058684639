import React from 'react';
import s from './AmountOptionMobile.module.scss';
import Button from '../../../Common/UI/Button/Button';
import {
  selectAmountRanges,
  selectDenomination,
  selectMaxBet,
} from '../../../../redux/slices/configs.slices';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAmount, setAmountOptiosn } from '../../../../redux/slices/game.slices';

function AmountOptionMobile({ selectedAmount }) {
  const dispatch = useDispatch();
  const denomination = useSelector(selectDenomination);
  const maxBet = useSelector(selectMaxBet);
  const amountRanges = useSelector(selectAmountRanges);

  const onAmountOptionClickMobile = (amounts) => {
    if (+selectedAmount + amounts <= +maxBet) {
      dispatch(setAmountOptiosn(amounts));
    } else {
      dispatch(setAmount(+maxBet));
    }
  };

  const onDubbleButtonClick = () => {
    if (selectedAmount * 2 <= +maxBet) {
      dispatch(setAmount(selectedAmount * 2));
    } else {
      dispatch(setAmount(+maxBet));
    }
  };

  return (
    <div className={s.amountMobile__fastBet}>
      <div className={s.amountMobile__bet}>
        <Button
          className={s.amountMobile__button}
          onClick={() => onDubbleButtonClick()}
          amountText={'2x'}
        />
      </div>
      {amountRanges.map((item) => {
        return (
          <div key={item} className={s.amountMobile__bet}>
            <Button
              className={s.amountMobile__button}
              amountText={`+${item}`}
              onClick={() => onAmountOptionClickMobile(item)}
            />
          </div>
        );
      })}
      <div className={s.amountMobile__bet}>
        <Button
          className={s.amountMobile__button}
          onClick={() => dispatch(setAmount(+maxBet))}
          amountText={'MAX'}
        />
      </div>
    </div>
  );
}

export default AmountOptionMobile;

import { createSlice } from '@reduxjs/toolkit';
import { gameVersionExtraReducer } from '../thunks/gameVersion.thunk';

const initialState = {
  gameVersion: [],
};

export const gameVersionSlices = createSlice({
  name: 'version',
  initialState,
  extraReducers: gameVersionExtraReducer,
  reducers: {
    setVersion: (state, { payload }) => {
      state.gameVersion = payload;
    },
  },
});

// ACTIONS

export const { setVersion } = gameVersionSlices.actions;

// SELECTORS
export const selectGameVersion = (state) => state.version.gameVersion;

export default gameVersionSlices.reducer;

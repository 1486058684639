import enLucky6 from './jsons/enLucky6.json';
import swLucky6 from './jsons/swLucky6.json';

const languages = {
  en: enLucky6,
  sw: swLucky6,
};

const getActiveLanguages = lang => languages[lang] || languages['en'];

export default getActiveLanguages;

import CongrateScene from '../components/Common/Process/CongrateScene/CongrateScene';
import DrawProcess from '../components/Common/Process/DrawProcess/DrawProcess';
import WaitingScene from '../components/Common/Process/WaitingScene/WaitingScene';
import { PROCESS_SCENE } from '../constants/game.constatnt';

const { CONGRATE_SCENE, WAITING_SCENE, DRAW_SCENE } = PROCESS_SCENE;

export const PROCESS_SCENE_CONFIGS = {
  [CONGRATE_SCENE]: CongrateScene,
  [WAITING_SCENE]: WaitingScene,
  [DRAW_SCENE]: DrawProcess,
};

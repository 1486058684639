import React from 'react';
import s from './Header.module.scss';
import Progress from '../Progress/Progress';
import Button from '../UI/Button/Button';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrency, selectUserBalance } from '../../../redux/slices/balance.slices';
import { selectUserId } from '../../../redux/slices/configs.slices';
import {
  selectActiveScene,
  setGameScene,
  setProcessScene,
} from '../../../redux/slices/scene.slices';
import { HEADER_BUTTONS, PROCESS_SCENE, SCENE_NAMES } from '../../../constants/game.constatnt';
import { MOBILE_TABS_CONFIGS } from '../../../configs/headerButtons.configs';
import { memo } from 'react';
import {
  resetPulledDigit,
  setBigDigit,
  setRaffleActive,
} from '../../../redux/slices/raffle.slices';
import { selectNewBalance } from '../../../redux/slices/sentTicket.slices';

const { MENU_SCENE, GAME_SCENE } = SCENE_NAMES;
const { WAITING_SCENE } = PROCESS_SCENE;

const Header = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });
  const dispatch = useDispatch();
  const activeScene = useSelector(selectActiveScene);
  const userId = useSelector(selectUserId);
  const userBalance = useSelector(selectUserBalance);
  const newUserBalance = useSelector(selectNewBalance);
  const currency = useSelector(selectCurrency);

  const onHomeButtonClick = () => {
    dispatch(setGameScene(MENU_SCENE));
    dispatch(setBigDigit(null));
    dispatch(resetPulledDigit());
    dispatch(setRaffleActive(false));
    dispatch(setProcessScene(WAITING_SCENE));
  };

  return (
    <header className={s.header}>
      <div className={s.headerContainer}>
        <div className={s.header__info}>
          <div className={s.headerHomepage}>
            <Button
              icon={'home'}
              onClick={() => onHomeButtonClick()}
              className={s.headerHomepage__button}
            />
          </div>
          <div className={s.headerBalance}>
            <div className={s.headerBalance__info}>
              <div className={s.headerBalance__count}>
                {newUserBalance ? newUserBalance : userBalance}
              </div>
              <div className={s.headerBalance__currency}>{currency ? currency : ''}</div>
            </div>
          </div>
          <div className={s.headerUserId}>
            ID:<span className={s.headerUserIdNumber}>{userId}</span>
          </div>
        </div>
        {!isMobile && <Progress className={s.progress} />}
        <div className={s.headerTools}>
          {Object.keys(HEADER_BUTTONS).map((button) => {
            const ButtonComponent = MOBILE_TABS_CONFIGS[button]?.Component;
            return (
              <div key={button} className={s.headerTools__action}>
                <ButtonComponent className={s.headerTools__button} button={button} />
              </div>
            );
          })}
        </div>
      </div>
      {activeScene == GAME_SCENE && isMobile && <Progress className={s.progress} />}
    </header>
  );
};

export default memo(Header);

import React from 'react';
import logo from '../../../../assets/images/Process/Logo.png';

function WaitingScene() {
  return (
    <div>
      <img src={logo} />
    </div>
  );
}

export default WaitingScene;

export function generateRandomDigits() {
  const btns = [];
  while (btns.length < 6) {
    const currentDigit = Math.floor(Math.random() * 47) + 1;
    !btns.includes(currentDigit) && btns.push(currentDigit);
  }

  return btns;
}

export function getCorrectTimeTillDraw(time) {
  if (time > 59) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  } else {
    return `00:${time < 10 ? `0${time}` : time}`;
  }
}

export function getBeforeAnimationTime(duration) {
  return duration === 1 ? `00:59` : `02:30`;
}

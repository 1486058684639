import React, { useContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getActiveLanguages from '../assets/languages';
import { getLanguageFromUrl } from '../helpers/general';
import { setActiveLanguage } from '../redux/slices/game.slices';

const LanguageContext = React.createContext({});

const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const language = getLanguageFromUrl();
  const languageData = getActiveLanguages(language);

  useEffect(() => {
    dispatch(setActiveLanguage(language));
  }, []);

  const t = useCallback(text => languageData[text] || text, [languageData]);

  return (
    <LanguageContext.Provider value={t}>{children}</LanguageContext.Provider>
  );
};

export const useTranslation = () => useContext(LanguageContext);

export default LanguageProvider;

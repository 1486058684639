import React, { memo } from 'react';
import s from './Ticket.module.scss';
import classNames from 'classnames';
import TicketHeader from './TicketHeader/TicketHeader';
import TicketBody from './TicketBody/TicketBody';
import TicketInfo from './TicketInfo/TicketInfo';

const Ticket = ({
  isRaffleActive,
  ticket,
  addedTicket,
  index,
  nextAddedTicket,
}) => {
  return (
    <div className={classNames(s.ticket, { [s.raffle]: isRaffleActive })}>
      <TicketHeader ticket={ticket} index={index} addedTicket={addedTicket} />
      <div className={s.ticket__body}>
        <TicketBody
          ticket={ticket}
          addedTicket={addedTicket}
          nextAddedTicket={nextAddedTicket}
        />
        <TicketInfo
          ticket={ticket}
          addedTicket={addedTicket}
          isRaffleActive={isRaffleActive}
        />
      </div>
    </div>
  );
};

export default memo(Ticket);

import React from 'react';
import s from './Popup.module.scss';
import errorIcon from '../../../assets/images/popup/error.png';
import closeIcon from '../../../assets/images/popup/close.png';
import reloadIcon from '../../../assets/images/popup/refresh.svg';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  selectErrorMassage,
  selectSessionErrorMassage,
  setPopupsDisable,
} from '../../../redux/slices/popup.slices';
import { POPUP_TYPES } from '../../../constants/game.constatnt';
import { useSelector } from 'react-redux';
import HowToPlay from './HowToPlay/HowToPlay';
import { useTranslation } from '../../../context/LanguageContext';

const { HOWTOPLAY, ERROR, SESSION_ERROR } = POPUP_TYPES;

const Popup = ({ isPopupActive }) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const errorMasage = useSelector(selectErrorMassage);
  const sessionErrorMassage = useSelector(selectSessionErrorMassage);

  const closePopup = () => {
    dispatch(setPopupsDisable(false));
  };

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div
      onClick={() => !(isPopupActive == SESSION_ERROR) && closePopup()}
      className={classNames(s.popup__container, {
        [s.instruction]: isPopupActive == HOWTOPLAY,
      })}>
      <div className={s.popup}>
        {isPopupActive == SESSION_ERROR && (
          <div className={s.popup__icon}>
            <div className={s.popup__errorText}>{t(sessionErrorMassage)}</div>
            <div className={s.popup__errorText}>{t('Reload')}</div>
            <img
              src={reloadIcon}
              alt="refresh"
              className={s.reload__img}
              onClick={() => refreshPage()}
            />
          </div>
        )}
        {isPopupActive == ERROR && (
          <div className={s.popup__icon}>
            <img src={errorIcon} alt="Warning" className={s.popup__img} />
            <div className={s.popup__errorText}>{errorMasage}</div>
          </div>
        )}
        {isPopupActive == HOWTOPLAY && <HowToPlay />}
        {!(isPopupActive == SESSION_ERROR) && (
          <div className={s.popup__close}>
            <button onClick={() => closePopup()} className={s.popup__closeButton}>
              <img src={closeIcon} alt="Close" className={s.popup__closeImg} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;

import React from 'react';
import s from './Amount.module.scss';
import { selectDenomination } from '../../../redux/slices/configs.slices';
import { useSelector } from 'react-redux';
import BetAmountOptions from './BetAmountOptions/BetAmountOptions';
import BetAmountInput from './BetAmountInput/BetAmountInput';

const Amount = () => {
  const denomination = useSelector(selectDenomination);

  return (
    <div className={s.amount}>
      <BetAmountOptions denomination={denomination} />
      <BetAmountInput />
    </div>
  );
};

export default Amount;

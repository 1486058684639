import React from 'react';
import s from './TicketHeader.module.scss';
import { getTimeFromIso } from '../../../../helpers/general';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectTicketInfo } from '../../../../redux/slices/ticket.slices';
import { useTranslation } from '../../../../context/LanguageContext';

function TicketHeader({ ticket, index, addedTicket }) {
  const t = useTranslation();
  const showTicketInfo = useSelector(selectTicketInfo);
  const isTicketWon = ticket?.ststatus == '2';
  const ticketIsWon = isTicketWon && showTicketInfo;
  const winCoefficient = ticket?.wsum / ticket?.sum;

  return (
    <div
      className={classNames(s.ticket__header, {
        [s.win]: ticketIsWon,
      })}>
      {addedTicket ? (
        <div className={s.addedTicket__header}>
          <div className={s.addedTicket__leftside}>
            <div>{index + 1}.</div>
            <div className={s.addedTicket__amount}>{`${t('Bet')} ${
              ticket.amount || ticket.sum
            }`}</div>
          </div>
          {ticketIsWon && <div>{winCoefficient}x</div>}
          <div>{getTimeFromIso(ticket.dt)}</div>
        </div>
      ) : (
        t('Please choose 6 numbers')
      )}
    </div>
  );
}

export default TicketHeader;

import React, { memo } from 'react';
import s from './Wrapper.module.scss';
import Header from '../Header/Header';
import { useSelector } from 'react-redux';
import { selectActiveScene } from '../../../redux/slices/scene.slices';
import { GAME_SCENE_CONFIGS } from '../../../configs/gameScene.consfigs';
import { SCENE_NAMES } from '../../../constants/game.constatnt';

const { LOADING_SCENE } = SCENE_NAMES;

const Wrapper = () => {
  const activeScene = useSelector(selectActiveScene);
  const activeSceneComponent = GAME_SCENE_CONFIGS[activeScene];

  return (
    <>
      <div className={s.wrapper}>
        {!(activeScene === LOADING_SCENE) && <Header />}
        <activeSceneComponent.Component />
      </div>
    </>
  );
};

export default memo(Wrapper);

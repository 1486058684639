import React from 'react';
import Digit from '../../../../DigitsBoard/Digit/Digit';
import s from './MyHistoryBody.module.scss';
import classNames from 'classnames';

function MyHistoryBody({ digits, winComb, canShowStatus }) {
  return (
    <div className={s.myHistory__winDigits}>
      <ul className={s.myHistory__digitList}>
        {digits.map((digit, index) => {
          const winDigits = winComb.includes(digit);
          const winindex = winComb.indexOf(digit);
          return (
            <li
              key={index}
              data-text={winDigits && canShowStatus ? winindex + 1 : null}
              className={classNames(s.myHistory__digitItem, {
                [s.static]: winDigits && canShowStatus,
              })}>
              <div
                className={classNames(s.myHistory__num, {
                  [s.active]: winDigits && canShowStatus,
                })}>
                <Digit digit={digit} className={s.myHistory__digit} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MyHistoryBody;

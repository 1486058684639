import GameScene from '../components/Common/GameScenes/GameScene/GameScene';
import LoadingScene from '../components/Common/GameScenes/LoadingScene/LoadingScene';
import StartPage from '../components/Common/StartPage/StartPage';
import { SCENE_NAMES } from '../constants/game.constatnt';

const { MENU_SCENE, LOADING_SCENE, GAME_SCENE } = SCENE_NAMES;

export const GAME_SCENE_CONFIGS = {
  [LOADING_SCENE]: {
    Component: LoadingScene,
  },
  [MENU_SCENE]: {
    Component: StartPage,
  },
  [GAME_SCENE]: {
    Component: GameScene,
  },
};

import React from 'react';
import s from './Button.module.scss';
import classNames from 'classnames';
import { useTranslation } from '../../../../context/LanguageContext';

const Button = ({ icon, text, amountText, className, onClick, mobileTabs }) => {
  const t = useTranslation();

  return (
    <button onClick={onClick} className={classNames(s.button, { [className]: className })}>
      {icon &&
        (!mobileTabs ? (
          <span className={s.button__icon}>
            <svg className={s.button__svg}>
              <use xlinkHref={`#${icon}`} />
            </svg>
          </span>
        ) : (
          <span className={s.mobileTab__icon}>
            <img className={s.mobileTab__svg} src={icon} />
          </span>
        ))}
      {text && <span className={s.button__text}>{t(text)}</span>}
      {amountText && <span className={s.button__amountText}>{amountText}</span>}
    </button>
  );
};

export default Button;

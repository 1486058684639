export const COEFFICIENT_BY_PLACE = {
  6: 15000,
  7: 9000,
  8: 6000,
  9: 3000,
  10: 1000,
  11: 500,
  12: 300,
  13: 200,
  14: 150,
  15: 100,
  16: 90,
  17: 80,
  18: 70,
  19: 60,
  20: 50,
  21: 40,
  22: 30,
  23: 25,
  24: 20,
  25: 15,
  26: 10,
  27: 9,
  28: 8,
  29: 7,
  30: 6,
  31: 5,
  32: 4,
  33: 3,
  34: 2,
  35: 1,
};

export const ALL_WINING_PLACES = Object.keys(COEFFICIENT_BY_PLACE);

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getBalanceThunk = createAsyncThunk(
  'balance/getBalanceThunk',
  async function () {
    return await axios.get(`get-balance`);
  },
);

export const balanceExtraReducer = builder => {
  builder
    .addCase(getBalanceThunk.pending, state => {
      state.isLoading = true;
    })
    .addCase(getBalanceThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.userBalance = Number(payload.balance);
      state.currency = payload.currency;
    })
    .addCase(getBalanceThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};

import { GAME_TABS } from '../constants/tabs.constants';
import Coefficient from '../components/Common/Tabs/Coefficient/Coefficient.jsx';
import History from '../components/Common/Tabs/History/History.jsx';
import Jackpot from '../components/Common/Tabs/Jackpot/Jackpot.jsx';
import Leaderboard from '../components/Common/Tabs/Leaderboard/Leaderboard.jsx';

const { COEFFICIENT, HISTORY, JACKPOT, LEADERBOARD } = GAME_TABS;

export const GAME_TABS_CONFIGS = {
  [COEFFICIENT]: {
    icon: 'coefficient',
    Component: Coefficient,
  },
  [HISTORY]: {
    icon: 'history',
    Component: History,
  },
  [JACKPOT]: {
    icon: 'jackpot',
    Component: Jackpot,
  },
  [LEADERBOARD]: {
    icon: 'leaderboard',
    Component: Leaderboard,
  },
};

import React from 'react';
import s from './BetAmountInput.module.scss';
import amountClear from '../../../../assets/images/digitsBoard/amountDelete.png';
import { useSelector } from 'react-redux';
import { selectAmount, setAmount } from '../../../../redux/slices/game.slices';
import { useDispatch } from 'react-redux';
import { selectMaxBet } from '../../../../redux/slices/configs.slices';
import cn from 'classnames';
import { setErrorActive } from '../../../../redux/slices/popup.slices';
import { selectCurrency } from '../../../../redux/slices/balance.slices';
import { useTranslation } from '../../../../context/LanguageContext';

function BetAmountInput() {
  const t = useTranslation();
  const selectedAmount = useSelector(selectAmount);
  const maxBet = useSelector(selectMaxBet);
  const currency = useSelector(selectCurrency);
  const dispatch = useDispatch();

  const handleSetAmount = (value) => {
    const regex = /^[0-9]{0,9}?\d*\.?\d*$/;
    const valid = regex.test(value);
    if (
      !valid ||
      value[0] === '0' ||
      value[0] === '.' ||
      (value.split('.').length > 1 && value.split('.')[1].length > 2)
    ) {
      return false;
    } else {
      +value <= +maxBet ? dispatch(setAmount(+value)) : setMaxBet();
    }
  };

  const setMaxBet = () => {
    dispatch(setAmount(+maxBet));
    dispatch(setErrorActive(`${t('Max bet is')} ${maxBet} ${currency}`));
  };

  const delHandleClick = () => {
    if (selectedAmount !== 0) {
      const amount = Math.floor(selectedAmount / 10);
      dispatch(setAmount(amount));
    }
  };

  return (
    <div className={s.amount__write}>
      <label className={s.amount__label}>
        <input
          placeholder={'Amount'}
          type="text"
          onChange={(e) => handleSetAmount(e.target.value)}
          value={selectedAmount === 0 ? '' : selectedAmount}
          className={s.amount__input}
        />
        <span className={s.amountClear}>
          <button
            onClick={delHandleClick}
            className={cn(s.amountClear__button, {
              [s.active]: selectedAmount,
            })}>
            <img src={amountClear} alt="" className={s.amountClear__img} />
          </button>
        </span>
      </label>
    </div>
  );
}

export default BetAmountInput;

import React, { useRef, useState } from 'react';
import { HISTORY_TABS_CONFIGS } from '../../../../configs/historyTabs.consfigs';
import { HISTORY_TABS } from '../../../../constants/tabs.constants';
import s from './History.module.scss';
import classNames from 'classnames';
import { useTranslation } from '../../../../context/LanguageContext';

const { DRAW_HISTORY } = HISTORY_TABS;
const historyTabs = Object.keys(HISTORY_TABS);

function History() {
  const t = useTranslation();
  const [activeTab, setActiveTab] = useState(DRAW_HISTORY);
  const activeHistoryTabComponent = HISTORY_TABS_CONFIGS[activeTab];
  const refContainer = useRef(null);

  return (
    <>
      <div className={s.historyTabs}>
        {historyTabs.map(tab => (
          <div key={tab} className={s.historyTab}>
            <div
              className={classNames(
                s.historyTitle,
                activeTab === HISTORY_TABS[tab] && s.active,
              )}
              onClick={() => setActiveTab(HISTORY_TABS[tab])}>
              {t(HISTORY_TABS[tab])}
            </div>
          </div>
        ))}
      </div>
      <div ref={refContainer} className={s.historyBody}>
        <activeHistoryTabComponent.Component
          scrollableTarget={refContainer.current}
        />
      </div>
    </>
  );
}

export default History;

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { STATUS_NUMBERS } from '../../../../constants/game.constatnt';
import { useTranslation } from '../../../../context/LanguageContext';
import { numberWithCommas } from '../../../../helpers/general';
import { selectMaxWin, selectOddMatrix } from '../../../../redux/slices/configs.slices';
import {
  selectActiveDigits,
  selectActiveLanguage,
  selectAmount,
} from '../../../../redux/slices/game.slices';
import { selectTicketInfo } from '../../../../redux/slices/ticket.slices';
import s from './TicketInfo.module.scss';

function TicketInfo({ addedTicket, ticket }) {
  const t = useTranslation();
  const activeLanguage = useSelector(selectActiveLanguage);
  const selectedAmount = useSelector(selectAmount);
  const coefficientMatrix = useSelector(selectOddMatrix);
  const showTicketInfo = useSelector(selectTicketInfo);
  const activeDigits = useSelector(selectActiveDigits);
  const maxWin = useSelector(selectMaxWin);
  const [possibleWin, setPossibleWin] = useState(0);
  const maxCoefficient = coefficientMatrix ? coefficientMatrix[5] : null;
  const isTicketWon = ticket?.ststatus == '2';

  useEffect(() => {
    if (maxCoefficient && activeDigits.length == 6) {
      const possibleWinCount = selectedAmount * maxCoefficient;
      if (possibleWinCount > maxWin) {
        setPossibleWin(maxWin);
      } else {
        setPossibleWin(possibleWinCount);
      }
    } else {
      setPossibleWin('---');
    }
  }, [selectedAmount, maxCoefficient, activeDigits]);

  return (
    <div className={s.ticket__info}>
      {addedTicket ? (
        <div className={s.addedTicket__info}>
          <div>{t('Status')}</div>
          <div
            className={classNames({
              [s.statusColor]: isTicketWon && showTicketInfo,
            })}>
            {!showTicketInfo ? STATUS_NUMBERS[0] : STATUS_NUMBERS[ticket?.ststatus]}
          </div>
        </div>
      ) : (
        <div className={s.ticket__possibleWin}>
          <div className={{ [s.psText]: activeLanguage == 'sw' }}>{t('Possible win')}</div>
          <div>{numberWithCommas(possibleWin)}</div>
        </div>
      )}
    </div>
  );
}

export default TicketInfo;

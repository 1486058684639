import classNames from 'classnames';
import React from 'react';
import s from './MyHistoryFooter.module.scss';

function MyHistoryFooter({ status, betting, winAmount, canShowStatus }) {
  return (
    <div className={s.myHistory__status}>
      <ul className={s.myHistory__statusList}>
        <li className={s.myHistory__statusItem}>
          <div className={s.myHistory__statusName}>Status</div>
          <div
            className={classNames(
              s.myHistory__statusName,
              status === 'Win' && canShowStatus ? s.win : '',
            )}>
            {canShowStatus ? status : '---'}
          </div>
        </li>
        <li className={s.myHistory__statusItem}>
          <div className={s.myHistory__statusName}>Betting</div>
          <div className={s.myHistory__statusName}>{betting}</div>
        </li>
        <li className={s.myHistory__statusItem}>
          <div className={s.myHistory__statusName}>Win</div>
          <div className={s.myHistory__statusName}>{status === '---' ? '---' : winAmount}</div>
        </li>
      </ul>
    </div>
  );
}

export default MyHistoryFooter;

import { createSlice } from '@reduxjs/toolkit';
import { balanceExtraReducer } from '../thunks/balance.thunk';

const initialState = {
  balance: '',
};

export const balanceSlices = createSlice({
  name: 'balance',
  initialState,
  extraReducers: balanceExtraReducer,
  reducers: {
    setBalance: (state, { payload }) => {
      state.balance = payload;
    },
  },
});

// ACTIONS

export const { setBalance } = balanceSlices.actions;

// SELECTORS
export const selectUserBalance = (state) => state.balance.userBalance;
export const selectCurrency = (state) => state.balance.currency;

export default balanceSlices.reducer;

import React from 'react';
import { useTranslation } from '../../../../context/LanguageContext';
import s from './CongrateScene.module.scss';

function CongrateScene() {
  const t = useTranslation();

  return (
    <div className={s.congText__container}>
      <span>{t('CONGRATULATIONS')}</span>
      <span>{t('TO THE WINNERS')}!</span>
    </div>
  );
}

export default CongrateScene;

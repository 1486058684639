import classNames from 'classnames';
import React from 'react';
import { useTranslation } from '../../../../context/LanguageContext';
import Digit from '../../DigitsBoard/Digit/Digit';
import s from './DrawProcess.module.scss';

function DrawProcess({ bigDigit, pulledDigit, drawId }) {
  const t = useTranslation();

  return (
    <div className={s.process__motion}>
      <div className={classNames(s.process__num, { [s.invisible]: !bigDigit })}>
        <Digit animate className={s.process__digit} digit={bigDigit} />
      </div>
      <div className={s.process__draw}>
        <div className={s.process__id}>{`${t('Draw ID:')} ${drawId}`}</div>
        <div className={s.process__counter}>
          <span>{pulledDigit.length}</span> / 35
        </div>
      </div>
    </div>
  );
}

export default DrawProcess;

import React, { useState } from 'react';
import { memo } from 'react';
import { MOBILE_TABS_CONFIGS } from '../../../../configs/headerButtons.configs';
import Button from '../../UI/Button/Button';

function FullScreenButton({ className, button }) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const icon = MOBILE_TABS_CONFIGS[button]?.icon;
  const activeIcon = MOBILE_TABS_CONFIGS[button]?.activeIcon;

  function onFullScreenClick() {
    let element = document.getElementById('root');
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setIsFullScreen(!isFullScreen);
    } else {
      document.exitFullscreen();
      setIsFullScreen(!isFullScreen);
    }
  }

  return (
    <Button
      className={className}
      icon={isFullScreen ? activeIcon : icon}
      onClick={onFullScreenClick}
    />
  );
}

export default memo(FullScreenButton);

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUserHistoryThunk = createAsyncThunk(
  'userHistory/getUserHistoryThunk',
  async function ({ duration, limit, page, raceId }) {
    const response = await axios.get(`get-user-draw-history`, {
      params: {
        duration,
        limit: limit || 10,
        page: page || 0,
      },
    });
    return { raceId, ...response };
  },
);

export const UserHistoryExtraReducer = builder => {
  builder
    .addCase(getUserHistoryThunk.pending, state => {
      state.isLoading = true;
    })
    .addCase(getUserHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.userHistory = payload.userFinishedStakes;
      state.userHistoryAddedTickets = payload.userFinishedStakes;
    })
    .addCase(getUserHistoryThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};

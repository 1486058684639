import currentMobile from './CurrentMobile.svg';
import currentMobileActive from './CurrentMobileActive.svg';
import History from './History.svg';
import HistoryActive from './HistoryActive.svg';
import Jackpot from './Jackpot.svg';
import JackpotActive from './JackpotActive.svg';
import Leaderboard from './Leaderboard.svg';
import LeaderboardActive from './LeaderboardActive.svg';

export const mobileTabsImgs = {
  currentMobile,
  currentMobileActive,
  History,
  HistoryActive,
  Jackpot,
  JackpotActive,
  Leaderboard,
  LeaderboardActive,
};

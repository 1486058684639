import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRaffleActive: false,
  bigDigit: null,
  raffledigit: [],
};

export const raffleSlice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    setRaffleActive: (state, { payload }) => {
      state.isRaffleActive = payload;
    },
    setBigDigit: (state, { payload }) => {
      state.bigDigit = payload;
    },
    setPulledDigit: (state, { payload }) => {
      state.raffledigit = [...state.raffledigit, payload].filter(
        (digit, index, array) => array.indexOf(digit) === index,
      );
    },
    resetPulledDigit: state => {
      state.raffledigit = [];
    },
  },
});

// ACTIONS
export const {
  setRaffleActive,
  setPulledDigit,
  resetPulledDigit,
  setBigDigit,
} = raffleSlice.actions;

// SELECTORS
export const selectDigits = state => state.raffle.raffledigit;
export const selectBigDigit = state => state.raffle.bigDigit;
export const selectRaffleActive = state => state.raffle.isRaffleActive;

export default raffleSlice.reducer;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { setupAxios } from './api/axios';
import App from './App';
import './assets/styles/index.scss';
import LanguageProvider from './context/LanguageContext';
import SoundProvider from './context/SoundContext';
import { store } from './redux/store';

setupAxios();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider>
        <SoundProvider>
          <App />
        </SoundProvider>
      </LanguageProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

import React, { createContext, useContext } from 'react';
import useSound from 'use-sound';
import sounds from '../assets/sounds';

const SoundContext = createContext();

const SoundProvider = ({ children }) => {
  const { click, win, bet, start, ballDrop } = sounds;

  const [clickSound] = useSound(click);
  const [winSound] = useSound(win);
  const [betSound] = useSound(bet);
  const [startSound] = useSound(start);
  const [ballDropSound] = useSound(ballDrop);

  const playSound = {
    click: () => clickSound(),
    win: () => winSound(),
    bet: () => betSound(),
    start: () => startSound(),
    ballDrop: () => ballDropSound(),
  };

  return <SoundContext.Provider value={playSound}>{children}</SoundContext.Provider>;
};

export const useSoundConstext = () => {
  return useContext(SoundContext);
};

export default SoundProvider;

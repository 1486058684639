import React from 'react';
import s from './AmountInputMobile.module.scss';
import amountClear from '../../../../assets/images/digitsBoard/amountDelete.png';
import { useDispatch } from 'react-redux';
import { setAmount } from '../../../../redux/slices/game.slices';

function AmountInputMobile({ selectedAmount }) {
  const dispatch = useDispatch();

  const onAmountClearClick = () => {
    if (selectedAmount !== 0) {
      const amount = Math.floor(selectedAmount / 10);
      dispatch(setAmount(amount));
    }
  };

  return (
    <div className={s.amountMobile__write}>
      <label className={s.amountMobile__label}>
        <input
          readOnly
          type="text"
          value={selectedAmount}
          className={s.amountMobile__input}
        />
        <span className={s.amountMobile__clear}>
          <button
            onClick={() => onAmountClearClick()}
            className={s.amountMobileClear__button}>
            <img src={amountClear} className={s.amountClear__img} />
          </button>
        </span>
      </label>
    </div>
  );
}

export default AmountInputMobile;

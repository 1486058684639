import React from 'react';
import DigitRow from './DigitRow/DigitRow';
import s from './DigitsBoard.module.scss';
import Amount from '../../Desktop/Amount/Amount';
import Submit from '../../Desktop/Submit/Submit';
import { colors } from '../../../constants/digits.constants';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectMinBet } from '../../../redux/slices/configs.slices';
import { setAmount } from '../../../redux/slices/game.slices';

function DigitsBoard() {
  const dispatch = useDispatch();
  const minBet = useSelector(selectMinBet);
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  useEffect(() => {
    dispatch(setAmount(minBet));
  }, []);

  return (
    <div className={s.digitsBoard}>
      {Object.keys(colors).map(rowColor => {
        return <DigitRow key={rowColor} rowColor={rowColor} />;
      })}
      {!isMobile && (
        <>
          <Amount />
          <Submit />
        </>
      )}
    </div>
  );
}

export default DigitsBoard;

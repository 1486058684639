import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getGameVersionThunk = createAsyncThunk(
  'version/getGameVersionThunk',
  async function () {
    return await axios.get(`get-game-variants`);
  },
);

export const gameVersionExtraReducer = (builder) => {
  builder
    .addCase(getGameVersionThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getGameVersionThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.gameVersion = payload;
    })
    .addCase(getGameVersionThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};

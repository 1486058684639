import React from 'react';
import GameTabs from '../GameTabs/GameTabs';
import s from './GamePlay.module.scss';
import Process from '../../Common/Process/Process';

function GamePlay() {
  return (
    <div className={s.gamePlay}>
      <Process />
      <GameTabs />
    </div>
  );
}

export default GamePlay;

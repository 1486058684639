import { createSlice } from '@reduxjs/toolkit';

const path = new URLSearchParams(window.location.search);
const { token } = Object.fromEntries(path.entries());

const initialState = {
  token: token,
};
export const tokenSlice = createSlice({
  name: 'token',
  initialState,
});

// SELECTORS
export const selectToken = state => state.token.token;

export default tokenSlice.reducer;

import classNames from 'classnames';
import React from 'react';
import {
  getDayAndMonth,
  getTimeFromIso,
} from '../../../../../../helpers/general';
import Digit from '../../../../DigitsBoard/Digit/Digit';
import s from './DrawHistoryContainer.module.scss';

function DrawHistoryContainer({ item }) {
  const dateHM = getTimeFromIso(item.dt);
  const dateMD = getDayAndMonth(item.dt);

  return (
    <li key={item.id} className={s.drawHistory__item}>
      <div className={s.drawHistory__header}>
        <div className={s.drawHistory__info}>{`ID: ${item.id}`}</div>
        <div className={s.drawHistory__info}>{`${dateMD} ${dateHM}`}</div>
      </div>
      <div className={s.drawHistory__body}>
        <ul className={s.drawHistory__digitList}>
          {item.r.map((numbers, index) => (
            <li
              key={numbers}
              data-text={index + 1}
              className={classNames(s.drawHistory__digitItem, s.static)}>
              <Digit digit={numbers} className={s.drawHistory__digit} />
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}

export default DrawHistoryContainer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUserCurrentHistoryThunk = createAsyncThunk(
  'userCurrentHistory/getUserCurrentHistoryThunk',
  async function ({ duration, raceId, drawId }) {
    const response = await axios.get(`get-user-current-history`, {
      params: {
        duration,
        drawId: raceId || drawId,
      },
    });
    return response;
  },
);

export const UserCurrentHistoryExtraReducer = builder => {
  builder
    .addCase(getUserCurrentHistoryThunk.pending, state => {
      state.isLoading = true;
    })
    .addCase(getUserCurrentHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.userCurrentHistory = payload.usersCurrentDrawStakes;
    })
    .addCase(getUserCurrentHistoryThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};

import React from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateRandomDigits } from '../../../../helpers/game';
import { selectActiveSound, setRandomDigits } from '../../../../redux/slices/game.slices';
import randomSix from '../../../../assets/images/RandomSix/random.png';
import s from './RandomSixButton.module.scss';
import { useSoundConstext } from '../../../../context/SoundContext';

function RandomSixButton() {
  const dispatch = useDispatch();
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectActiveSound);

  const onRandomButtonClick = () => {
    dispatch(setRandomDigits(generateRandomDigits()));
    isSoundActive && useSoundData.click();
  };

  return (
    <div className={s.submit__randomSix}>
      <button className={s.submit__randomSixButton}>
        <img
          src={randomSix}
          alt="Random Six"
          className={s.submit__randomSixImg}
          onClick={() => onRandomButtonClick()}
        />
      </button>
    </div>
  );
}

export default memo(RandomSixButton);

import React, { useEffect } from 'react';
import s from './StartPage.module.scss';
import { useMediaQuery } from 'react-responsive';
import Progress from '../Progress/Progress';
import StartPageImg from './StartPageImg/StartPageImg';
import StartPageDurations from './StartPageDurations/StartPageDurations';
import StartPageFotter from './StartPageFotter/StartPageFotter';
import { useDispatch, useSelector } from 'react-redux';
import { setGameScene } from '../../../redux/slices/scene.slices';
import { SCENE_NAMES } from '../../../constants/game.constatnt';
import { getUserCurrentHistoryThunk } from '../../../redux/thunks/userCurrentHistory.thunk';
import { selectDuration, setGameDuration } from '../../../redux/slices/game.slices';
import { selectRaceId } from '../../../redux/slices/time.slices';
import { useTranslation } from '../../../context/LanguageContext';
import { selectGameVersion } from '../../../redux/slices/gameVersion.slices';

const { GAME_SCENE } = SCENE_NAMES;

const StartPage = () => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const duration = useSelector(selectDuration);
  const gameVersion = useSelector(selectGameVersion);
  const raceId = useSelector(selectRaceId);
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  const isSingleVersion = gameVersion.length > 1;

  const onPlayButtonClick = () => {
    dispatch(setGameScene(GAME_SCENE));
    dispatch(getUserCurrentHistoryThunk({ duration, raceId }));
  };

  return (
    <div className={s.startPage}>
      <div className={s.startPage__body}>
        <StartPageImg />
        {isMobile && (
          <div className={s.startPage__loading}>
            <Progress loading={true} />
          </div>
        )}
        <div className={s.startPage__action}>
          {isSingleVersion && <StartPageDurations />}
          <div className={s.startPage__play}>
            <button onClick={() => onPlayButtonClick()} className={s.startPage__playBtn}>
              {t('Play')}
            </button>
          </div>
          {/* <div className={s.startPage__demo}>
            <button className={s.startPage__demoBtn}>{t('Demo')}</button>
          </div> */}
        </div>
      </div>
      <StartPageFotter />
    </div>
  );
};

export default StartPage;

import React, { memo } from 'react';
import s from './Submit.module.scss';
import { useMediaQuery } from 'react-responsive';
import MobileSubmit from '../../Mobile/MobileSubmit/MobileSubmit';
import BetButton from './BetButton/BetButton';
import RandomSixButton from './RandomSixButton/RandomSixButton';

const Submit = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  return (
    <div className={s.submit}>
      <RandomSixButton />
      {isMobile && <MobileSubmit />}
      <BetButton />
    </div>
  );
};

export default memo(Submit);

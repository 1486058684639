import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMaxBet } from '../../../../redux/slices/configs.slices';
import { setAmount } from '../../../../redux/slices/game.slices';
import s from './AmountNumbersMobile.module.scss';

const numbersArr = new Array(10).fill(null);

function AmountNumbersMobile({ selectedAmount }) {
  const dispatch = useDispatch();
  const maxBet = useSelector(selectMaxBet);

  const onAmountNumbersClick = (number) => {
    const amount = `${selectedAmount}` + `${number}`;
    if (Number(amount) <= maxBet) {
      dispatch(setAmount(Number(amount)));
    } else {
      dispatch(setAmount(maxBet));
    }
  };

  return (
    <div className={s.amountMobile__numbers}>
      {numbersArr.map((_, index) => (
        <div
          key={index}
          onClick={() => onAmountNumbersClick(index)}
          className={s.amountMobile__number}>
          {index}
        </div>
      ))}
    </div>
  );
}

export default AmountNumbersMobile;

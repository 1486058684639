import React from 'react';
import s from './GameboardMobile.module.scss';
import Ticket from '../../Common/Ticket/Ticket';
import GamePlayMobile from '../GamePlayMobile/GamePlayMobile';
import Process from '../../Common/Process/Process';
import logo from '../../../assets/images/Logo/logo.png';
import { useSelector } from 'react-redux';
import { selectRaffleActive } from '../../../redux/slices/raffle.slices';
import { memo } from 'react';
import { selectUserCurrentHistory } from '../../../redux/slices/userCurrentHistory.slices';
import classNames from 'classnames';

const GameboardMobile = ({ isCurrentTabActive }) => {
  const isRaffleActive = useSelector(selectRaffleActive);
  const userCurrentHistory = useSelector(selectUserCurrentHistory);
  const addedTickets = [...userCurrentHistory];

  return (
    <div
      className={classNames(s.gameboardMobile, {
        [s.visibility]: !isCurrentTabActive,
        [s.active]: !isRaffleActive,
      })}>
      {isRaffleActive ? (
        <Process />
      ) : (
        <div className={s.gameboardMobile__container}>
          {!isRaffleActive && (
            <div className={s.logo_container}>
              <img className={s.logo} src={logo} alt='' />
            </div>
          )}
          <div
            className={classNames(s.gameboardMobile__tickets, {
              [s.noTickets]: !addedTickets.length,
            })}>
            <Ticket />
            {addedTickets &&
              addedTickets.map((ticket, index) => {
                return <Ticket key={index} index={index} addedTicket ticket={ticket} />;
              })}
          </div>
        </div>
      )}
      <GamePlayMobile isRaffleActive={isRaffleActive} />
    </div>
  );
};

export default memo(GameboardMobile);

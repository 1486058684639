import FullScreenButton from '../components/Common/Header/FullScreenButton/FullScreenButton';
import HowToPlayButton from '../components/Common/Header/HowToPlayButton/HowToPlayButton';
import SoundButton from '../components/Common/Header/SoundButton/SoundButton';
import { HEADER_BUTTONS } from '../constants/game.constatnt';

const { HOWTOPLAY_BUTTON, SOUND_BUTTON, FULLSCREEN_BUTTON } = HEADER_BUTTONS;

export const MOBILE_TABS_CONFIGS = {
  [HOWTOPLAY_BUTTON]: {
    text: 'How to Play',
    icon: 'info',
    Component: HowToPlayButton,
  },
  [SOUND_BUTTON]: {
    text: 'Off',
    activeText: 'On',
    icon: 'soundOn',
    activeIcon: 'soundOff',
    Component: SoundButton,
  },
  [FULLSCREEN_BUTTON]: {
    icon: 'fullscreen',
    activeIcon: 'fullscreenExit',
    Component: FullScreenButton,
  },
};

import { createSlice } from '@reduxjs/toolkit';
import { PROCESS_SCENE, SCENE_NAMES } from '../../constants/game.constatnt';

const { MENU_SCENE } = SCENE_NAMES;
const { WAITING_SCENE } = PROCESS_SCENE;

const initialState = {
  activeScene: MENU_SCENE,
  processScene: WAITING_SCENE,
};

export const sceneSlices = createSlice({
  name: 'scene',
  initialState,
  reducers: {
    setGameScene: (state, { payload }) => {
      state.activeScene = payload;
    },
    setProcessScene: (state, { payload }) => {
      state.processScene = payload;
    },
  },
});

// ACTIONS
export const { setProcessScene, setGameScene } = sceneSlices.actions;

// SELECTORS
export const selectActiveScene = state => state.scene.activeScene;
export const selectProcessScene = state => state.scene.processScene;

export default sceneSlices.reducer;

import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  selectDenomination,
  selectMaxBet,
  selectMinBet,
} from '../../../redux/slices/configs.slices';
import {
  selectAmount,
  selectMobileInput,
  setAmount,
  setAmountOptiosn,
  setMobileInput,
} from '../../../redux/slices/game.slices';
import Button from '../../Common/UI/Button/Button';
import AmountMobile from '../AmountMobile/AmountMobile';
import s from './MobileSubmit.module.scss';

function MobileSubmit() {
  const dispatch = useDispatch();
  const selectedAmount = useSelector(selectAmount);
  const denomination = useSelector(selectDenomination);
  const isMobileInputActive = useSelector(selectMobileInput);
  const maxBet = useSelector(selectMaxBet);
  const minBet = useSelector(selectMinBet);

  const onPlusClick = () => {
    if (+selectedAmount + +denomination < +maxBet) {
      dispatch(setAmountOptiosn(+denomination));
    } else {
      dispatch(setAmount(+maxBet));
    }
  };

  const onMinusClick = () => {
    if (+selectedAmount - +denomination > +minBet) {
      dispatch(setAmountOptiosn(-denomination));
    } else {
      dispatch(setAmount(+minBet));
    }
  };

  return (
    <div className={s.submit__sum}>
      <div className={s.submit__sumChange}>
        <Button onClick={() => onMinusClick()} className={s.submit__sumChangeButton} text={'-'} />
      </div>

      <div className={s.submit__count} onClick={() => dispatch(setMobileInput())}>
        {selectedAmount}
      </div>
      <div className={s.submit__sumChange}>
        <Button
          onClick={onPlusClick}
          className={classNames(s.submit__sumChangeButton, s.reduce)}
          text={'+'}
        />
      </div>
      {isMobileInputActive && <AmountMobile />}
    </div>
  );
}

export default MobileSubmit;

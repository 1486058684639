import React from 'react';
import s from './StartPageImg.module.scss';
import logo from '../../../../assets/images/Logo/logo.png';

function StartPageImg() {
  return (
    <div className={s.startPage__logo}>
      <img className={s.startPage__img} src={logo} alt="Lu6ky Six" />
    </div>
  );
}

export default StartPageImg;

import React from 'react';
import classNames from 'classnames';
import Button from '../../../Common/UI/Button/Button';
import s from './BetButton.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetActiveDigits,
  selectActiveDigits,
  selectActiveSound,
  selectAmount,
  selectDuration,
  setAmount,
} from '../../../../redux/slices/game.slices';
import { selectMinBet } from '../../../../redux/slices/configs.slices';
import {
  selectIsNextDrawTicket,
  // addTicket,
  selectTickets,
} from '../../../../redux/slices/ticket.slices';
import { getHourseAndMinutes } from '../../../../helpers/general';
import { selectRaceId } from '../../../../redux/slices/time.slices';
import { sentTicketThunk } from '../../../../redux/thunks/sentTicket.thunk';
import { selectCurrency, selectUserBalance } from '../../../../redux/slices/balance.slices';
import { getUserCurrentHistoryThunk } from '../../../../redux/thunks/userCurrentHistory.thunk';
import { setErrorActive } from '../../../../redux/slices/popup.slices';
import { selectBetLoading } from '../../../../redux/slices/sentTicket.slices';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from '../../../../context/LanguageContext';
import { selectRaffleActive } from '../../../../redux/slices/raffle.slices';
import { useSoundConstext } from '../../../../context/SoundContext';

function BetButton() {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [isBetButtonActive, setBetButtonActive] = useState(false);
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectActiveSound);
  const selectedAmount = useSelector(selectAmount);
  const isRaffleActive = useSelector(selectRaffleActive);
  const isNextDrawTicket = useSelector(selectIsNextDrawTicket);
  const userBalance = useSelector(selectUserBalance);
  const duration = useSelector(selectDuration);
  const minBet = useSelector(selectMinBet);
  const currency = useSelector(selectCurrency);
  const activeDigits = useSelector(selectActiveDigits);
  const tickets = useSelector(selectTickets);
  const raceId = useSelector(selectRaceId);
  const isBetLoading = useSelector(selectBetLoading);

  useEffect(() => {
    if (selectedAmount >= minBet && activeDigits.length == 6) {
      setBetButtonActive(true);
    } else {
      setBetButtonActive(false);
    }
  }, [selectedAmount, activeDigits]);

  async function onBetButtonClick() {
    if (isBetButtonActive) {
      const ticketHeadNumber = tickets.length + 1;
      if (userBalance >= selectedAmount) {
        const madeTicket = makeTicket(ticketHeadNumber);
        (isNextDrawTicket || isRaffleActive) &&
          dispatch(setErrorActive(t('The bet will be accepted for the next draw')));
        await dispatch(sentTicketThunk(madeTicket));
        await dispatch(
          getUserCurrentHistoryThunk({
            duration,
            raceId: isNextDrawTicket ? raceId + 1 : raceId,
          }),
        );
        // dispatch(addTicket(madeTicket));
        isSoundActive && useSoundData.bet();
        dispatch(resetActiveDigits());
      } else if (userBalance - selectedAmount < 0) {
        dispatch(setErrorActive(t('Your balance is not enough, please refill it.')));
      }
    } else if (activeDigits.length !== 6) {
      dispatch(setErrorActive(t('The ticket must include 6 numbers')));
    } else if (selectedAmount < minBet) {
      dispatch(setAmount(minBet));
      dispatch(setErrorActive(`${t('Min bet is')} ${minBet} ${currency}`));
    }
  }

  const makeTicket = (ticketHeadNumber) => {
    const date = new Date();
    const dateHM = getHourseAndMinutes(date);

    return {
      id: ticketHeadNumber,
      bet: activeDigits,
      duration: duration,
      raceId: isNextDrawTicket ? raceId + 1 : raceId,
      amount: selectedAmount,
      date: dateHM,
    };
  };

  return (
    <div className={s.submit__submit}>
      <Button
        className={classNames(s.submit__button, {
          [s.disabled]: !isBetButtonActive || activeDigits.length !== 6,
        })}
        text={!isBetLoading && 'BET'}
        onClick={() => onBetButtonClick()}
      />
      {isBetLoading && <div className={s.load} />}
    </div>
  );
}

export default BetButton;

import React, { useEffect, useRef } from 'react';
import s from './Coefficient.module.scss';
import Digit from '../../DigitsBoard/Digit/Digit';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectDigits } from '../../../../redux/slices/raffle.slices';
import { selectOddMatrix } from '../../../../redux/slices/configs.slices';
import { useMediaQuery } from 'react-responsive';

function Coefficient({ isRaffleActive }) {
  const pulledDigit = useSelector(selectDigits);
  const coefficientMatrix = useSelector(selectOddMatrix);
  const bottomRef = useRef(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [pulledDigit]);

  return (
    <div className={classNames(s.coefficient, { [s.raffle]: isRaffleActive })}>
      <ul className={s.coefficient__list}>
        {coefficientMatrix?.map((number, index) => (
          <li
            key={index}
            className={classNames(s.coefficient__item, {
              [s.empty]: !isRaffleActive,
              [s.raffle]: isRaffleActive,
            })}>
            <div
              className={classNames(s.coefficient__count, !number && s.static)}>
              <div className={s.coefficient__number}>{index + 1}</div>
              <div className={s.coefficient__multiplier}>
                {number === 0 ? '' : `x${number}`}
              </div>
            </div>
            <div
              key={index}
              className={classNames(
                s.coefficient__pool,
                !pulledDigit[index] &&
                  (isRaffleActive ? s.invisible : s.hidden),
              )}>
              <div className={s.coefficient__num}>
                <Digit
                  animate
                  digit={pulledDigit ? pulledDigit[index] : []}
                  className={s.coefficient__digit}
                />
              </div>
            </div>
            {isMobile && pulledDigit[index] && <div ref={bottomRef} />}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Coefficient;

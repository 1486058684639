import React, { useEffect } from 'react';
import s from './Process.module.scss';
import {
  resetPulledDigit,
  selectBigDigit,
  selectDigits,
  selectRaffleActive,
  setBigDigit,
  setPulledDigit,
  setRaffleActive,
} from '../../../redux/slices/raffle.slices';
import { useDispatch, useSelector } from 'react-redux';
import { selectProcessScene, setProcessScene } from '../../../redux/slices/scene.slices';
import { PROCESS_SCENE_CONFIGS } from '../../../configs/process.configs';
import { PROCESS_SCENE } from '../../../constants/game.constatnt';
import { selectDrawId, selectWinCombination } from '../../../redux/slices/drawHistory.slices';
import { selectRaceId } from '../../../redux/slices/time.slices';
import { getUserHistoryThunk } from '../../../redux/thunks/userHistory.thunk';
import { selectActiveSound, selectDuration } from '../../../redux/slices/game.slices';
import { getUserCurrentHistoryThunk } from '../../../redux/thunks/userCurrentHistory.thunk';
import { selectIsNextDrawTicket, setTicketInfo } from '../../../redux/slices/ticket.slices';
import { useMediaQuery } from 'react-responsive';
import { useSoundConstext } from '../../../context/SoundContext';

let intervalIds = [];

const { CONGRATE_SCENE, WAITING_SCENE, DRAW_SCENE } = PROCESS_SCENE;

const Process = () => {
  const dispatch = useDispatch();
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectActiveSound);
  const pulledDigit = useSelector(selectDigits);
  const bigDigit = useSelector(selectBigDigit);
  const duration = useSelector(selectDuration);
  const processScene = useSelector(selectProcessScene);
  const winCombination = useSelector(selectWinCombination);
  const raceId = useSelector(selectRaceId);
  const drawId = useSelector(selectDrawId);
  const isRaffleActive = useSelector(selectRaffleActive);
  const isNextDrawTicket = useSelector(selectIsNextDrawTicket);
  const ProcessActiveScene = PROCESS_SCENE_CONFIGS[processScene];

  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  useEffect(() => {
    if (bigDigit) {
      isSoundActive && useSoundData.ballDrop();
    }
  }, [bigDigit]);

  useEffect(() => {
    if (isRaffleActive) {
      (async function () {
        dispatch(getUserHistoryThunk({ duration, raceId }));
        dispatch(
          getUserCurrentHistoryThunk({ duration, raceId: isNextDrawTicket ? raceId + 1 : raceId }),
        );
        isSoundActive && useSoundData.start();
        dispatch(setProcessScene(DRAW_SCENE));
        await pullBalls();
        await waiting(2000);
        dispatch(resetPulledDigit());
        !isMobile && dispatch(setRaffleActive(false));
        dispatch(setProcessScene(CONGRATE_SCENE));
        await waiting(2000);
        dispatch(setProcessScene(WAITING_SCENE));
        isMobile && dispatch(setRaffleActive(false));
        isMobile && dispatch(getUserCurrentHistoryThunk({ duration, raceId: raceId + 1 }));
        dispatch(setTicketInfo(false));
      })();
    }

    !isMobile &&
      dispatch(
        getUserCurrentHistoryThunk({ duration, raceId: isNextDrawTicket ? raceId + 1 : raceId }),
      );

    return () => {
      intervalIds.forEach((id) => clearInterval(id));
      intervalIds = [];
    };
  }, [isRaffleActive, winCombination]);

  useEffect(() => {
    waiting(1000).then(() => {
      bigDigit && dispatch(setPulledDigit(bigDigit));
    });
  }, [bigDigit]);

  return (
    <div className={s.process}>
      <ProcessActiveScene pulledDigit={pulledDigit} bigDigit={bigDigit} drawId={drawId} />
    </div>
  );

  function pullBalls() {
    return new Promise((resolve) => {
      if (winCombination) {
        const winCombinationForShift = [...winCombination];

        const id = window.setInterval(() => {
          const isRaffleFinished = !winCombinationForShift.length;
          if (!isRaffleFinished) {
            const pulledDigit = Number(winCombinationForShift.shift());
            dispatch(setBigDigit(pulledDigit));
          } else if (isRaffleFinished) {
            dispatch(setTicketInfo(true));
            waiting(1000).then(() => {
              dispatch(setBigDigit(null));
              resolve();
            });
            window.clearInterval(id);
          }
        }, 1500);

        intervalIds.push(id);
      }
    });
  }

  function waiting(ms) {
    return new Promise((resolve) => {
      const id = setTimeout(() => resolve(), ms);
      intervalIds.push(id);
    });
  }
};

export default Process;

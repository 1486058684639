import classNames from 'classnames';
import React from 'react';
import { useTranslation } from '../../../../../../context/LanguageContext';
import { getDayAndMonth, getTimeFromIso } from '../../../../../../helpers/general';
import s from './MyHistoryHeader.module.scss';

function MyHistoryHeader({ id, draw, date, status, canShowStatus }) {
  const dateHM = getTimeFromIso(date);
  const dateMD = getDayAndMonth(date);
  const t = useTranslation();

  return (
    <div
      className={classNames(s.myHistory__header, status === 'Win' && canShowStatus ? s.win : '')}>
      <div className={s.myHistory__info}>{`ID: ${id}`}</div>
      <div className={s.myHistory__info}>{`${t('Draw ID')}: ${draw}`}</div>
      <div className={s.myHistory__info}>{`${dateMD} ${dateHM}`}</div>
    </div>
  );
}

export default MyHistoryHeader;
